import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'

import Header from '../components/public/shared/Header'
import Footer from '../components/public/shared/Footer'
import ChallengeSpaceCard from '../components/public/challenge-space/ChallengeSpaceCard'
import { setFeaturedChallengeSpaces } from '../redux/actions/challenge-spaces'
import { isAuthenticated } from '../utils/auth'
import { wrapper } from '../redux/store'
import Image from 'next/image'

import headerImg from '../public/static/img/problem_solving.jpg'
import YellowBlob from '../assets/svg/yellow_blob.svg'
import LogoList, { LogoProps } from 'components/shared/LogoList'

const Yellow = () => <YellowBlob />

interface IndexProps {
	authenticated: boolean
	subjects: any
	organisations: any
	challengeSpace: any
}

export const getServerSideProps = wrapper.getServerSideProps(
	(store) => async (ctx) => {
		const params = {
			page: {
				number: 1,
				limit: 6,
			},
			filter: {
				is_public: 1,
				state: 1,
				use_challenges: 1,
			},
			sort: '-created_at',
		}

		await store.dispatch(setFeaturedChallengeSpaces(params))
		const storeData = store.getState()

		return {
			props: {
				challengeSpace: storeData.public.challengeSpace,
				subjects: storeData.public.subject,
				organisations: storeData.public.organisation,
				authenticated: isAuthenticated(ctx),
			},
		}
	}
)

// Port in to using useState hooks, if you need state
class Index extends React.Component<IndexProps> {
	constructor(props: any) {
		super(props)
	}

	render() {
		const { authenticated, challengeSpace, subjects, organisations } =
			this.props

		const logos: LogoProps[] = [
			{
				src: '/static/img/companies/McKinsey&Company.png',
				alt: 'McKinsey & Company.png',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/gospooky.svg',
				alt: 'GoSpooky',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/KPN-Logo.png',
				alt: 'KPN',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/Lalaland-logo.png',
				alt: 'Lalaland',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/agp_logo.png',
				alt: 'AGP',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/EY_logo_2019.svg.png',
				alt: 'EY',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/DANONE_LOGO.png',
				alt: 'DANONE',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/wetransfer.png',
				alt: 'Wetransfer',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/Team_Liquid_logo.png',
				alt: 'Team Liquid',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
			{
				src: '/static/img/companies/politieacademie_logo.png',
				alt: 'Politieacademie',
				maxWidth: '7rem',
				maxHeight: '5rem',
			},
		]

		return (
			<div className="wrapper">
				<Header authenticated={authenticated} />
				<main className="pt-20 relative z-20">
					<div className="relative h-full z-20 bg-purple-900">
						<div className="container mx-auto px-4 pt-8 md:pt-20 pb-32 relative z-20">
							<div className="w-full md:w-1/2 mt-4">
								<h1 className="text-3xl md:text-5xl mb-4 font-sans font-bold text-white">
									Your company challenge solved by{' '}
									<span className="text-yellow-300">students</span>
								</h1>
								<h2 className="text-lg md:text-xl mb-4 font-sans font-normal text-white opacity-75">
									Get fresh insights and access to a pool of talents
								</h2>
								<Link href="/challenge-spaces" passHref>
									<button className="bg-yellow-300 text-lg md:text-xl text-black hover:bg-yellow-200 transition-colors duration-300">
										Submit Challenge
									</button>
								</Link>
							</div>
						</div>
						<div className="bg-gradient-to-b md:bg-gradient-to-r z-10 absolute from-purple-900 h-1/2 md:h-full right-0 bottom-0 top-auto md:top-0 w-full md:w-7/12"></div>
						<div className="absolute right-0 bottom-0 top-auto md:top-0 h-1/2 md:h-full w-full md:w-7/12">
							<Image
								layout="fill"
								objectFit="cover"
								objectPosition="center"
								src={headerImg}
								alt="Problem solving"
							/>
						</div>
					</div>

					<LogoList
						title="Companies that worked with us before"
						logos={logos}
						numColumns={5}
					/>

					<div className="relative pb-10 border-t bg-gradient-to-b from-gray-50">
						<div className="container mx-auto px-4 relative z-10">
							<div className="max-w-4xl text-center mx-auto pt-10 my-10">
								<h2 className="font-sans font-bold mb-6 text-black text-3xl md:text-4xl">
									Get fresh insights and access to a pool of talents
								</h2>
								<p className="font-sans text-lg md:text-xl text-center text-gray-800 mx-auto">
									MasterChallenge is a triple win real-life challenge platform
									allowing{' '}
									<span className="font-bold text-purple-900">students</span> to
									gain relevant experience,{' '}
									<span className="font-bold text-purple-900">educators</span>{' '}
									to improve their course and{' '}
									<span className="font-bold text-purple-900">companies</span>{' '}
									to get their challenges solved.
								</p>
							</div>
						</div>

						<div className="container mx-auto relative z-10">
							<div className="flex flex-wrap">
								{challengeSpace &&
									Object.keys(challengeSpace).map((key, index) => {
										const subject =
											subjects[
												challengeSpace[key].relationships?.subject?.data?.id
											]
										const organisation =
											organisations[
												challengeSpace[key].relationships.organisation.data.id
											]

										return (
											<div
												className="px-4 w-full md:w-1/2 lg:w-1/3 mb-8"
												key={index}
											>
												<ChallengeSpaceCard
													space={challengeSpace[key]}
													subject={subject}
													organisation={organisation}
												/>
											</div>
										)
									})}
							</div>

							<div className="container mx-auto px-4 pb-10 mb-20 relative z-10">
								<div className="flex flex-wrap items-center justify-center">
									<h2 className="font-sans font-semibold text-gray-900 text-2xl mr-10">
										Curious to see all challenge spaces?
									</h2>
									<Link href="/challenge-spaces" passHref>
										<button className="bg-gray-900 transition-colors duration-300 hover:bg-gray-800 text-white my-4">
											Explore more
										</button>
									</Link>
								</div>
							</div>
						</div>

						<div className="absolute left-0 top-64 pt-10 pb-10 w-1/2">
							<div
								className="relative h-0 w-full p-0 index__blob-wrapper"
								style={{ paddingBottom: '81.319444%' }}
							>
								<Yellow />
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</div>
			/* <div className="max-w-4xl bg-white mx-auto rounded-lg overflow-hidden border border-gray-200 shadow-lg flex flex-wrap">

					<div className="relative px-6 py-10 text-center border-b md:border-r md:border-b-0 w-full md:w-1/2">
						<div className="absolute z-10 h-0 w-1/3 -ml-4 -mb-10 bottom-0 left-0 p-0 index__illustration-wrapper" style={{ paddingBottom: '27.7837543496%' }}>
							<Educators />
						</div>
						<h2 className="font-sans font-bold text-3xl mb-4">Educators</h2>
						<p className="font-mono mb-4 text-lg">Easily offer your students real life challenges from actual companies and make your course more relevant.</p>
						<Link href="/for-educators">
							<button className="bg-purple-900 hover:bg-purple-800 text-white">Learn more</button>
						</Link>
					</div>

					<div className="relative px-6 py-10 text-center w-full md:w-1/2">
						<div className="absolute z-10 h-0 w-1/3 -mr-4 -mb-10 bottom-0 right-0 p-0 index__illustration-wrapper" style={{ paddingBottom: '18.7428818227%' }}>
							<Companies />
						</div>
						<h2 className="font-sans font-bold text-3xl mb-4">Companies</h2>
						<p className="font-mono mb-4 text-lg">Let students solve your challenges. Receive solutions and get access to talent.</p>
						<Link href="/for-companies">
							<button className="bg-blue-600 hover:bg-blue-700 text-white">Learn more</button>
						</Link>
					</div>

					<div className="relative px-6 py-10 border-t w-full text-center">
						<h2 className="font-sans font-bold text-3xl mb-4">Students</h2>
						<p className="font-mono mb-4 mx-auto max-w-xl text-lg">Gain relevant experience and earn study credits by solving real-life challenges from actual companies as part of your course.</p>
						<Link href="/for-students">
							<button className="bg-yellow-400 hover:bg-yellow-500 text-black">Learn more</button>
						</Link>
					</div>

				</div> */
		)
	}
}

export default connect()(Index)
